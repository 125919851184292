import React, { useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { GridColumn } from '@progress/kendo-react-grid';

import { TenantContext } from "./state";
import { KendoGrid } from "./kendo-grid";

import useFetch, { FetchResponse } from "./useFetch";
import { SortDescriptor } from "@progress/kendo-data-query";

interface ActivityGridRow {
    firstName: string,
    lastName: string,
    organization: string,
    isAdmin: boolean,
    isAdminDisplayValue: string,
    actionDisplayName: string,
    activityType: string,
    targetId: string,
    targetName: string,
    date: Date
}

export const ActivitiesPage = () => {
    // State
    const [activities, setActivities] = useState<any[]>([]);
    const tenantState = useContext(TenantContext);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const activitiesApi = useFetch("/api/activity");

    // Effects
    useEffect(() => {
        if (isAuthenticated && tenantState.currentTenant) {
            activitiesApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const activitiesResponse: any[] = response.result.activities;
                        const activities: ActivityGridRow[] = activitiesResponse.map<ActivityGridRow>((activityResponse: any) => {
                            return {
                                firstName: activityResponse.firstName,
                                lastName: activityResponse.lastName,
                                organization: activityResponse.organization,
                                isAdmin: activityResponse.isAdmin,
                                isAdminDisplayValue: activityResponse.isAdmin ? "Yes" : "No",
                                actionDisplayName: getActionDisplayName(activityResponse.activityType),
                                activityType: activityResponse.activityType,
                                targetId: activityResponse.targetId,
                                targetName: activityResponse.targetName,
                                date: new Date(activityResponse.date)
                            };
                        });
                        setActivities(activities);
                    }
                });
        }
    }, [isAuthenticated, tenantState]);

    // Methods
    const sort: SortDescriptor[] = [
        { field: 'date', dir: 'desc' }
    ];

    const getActionDisplayName = (activityType: string): string => {
        switch (activityType) {
            case "AddDocument":
                return "Add Document";
            case "ViewDocument":
                return "View Document";
            case "EditDocument":
                return "Modify Document";
            case "AssignAdmin":
                return "Grant Admin Access";
            case "Login":
                return "Login";
            default:
                return "";
        }
    }

    const getTargetDisplay = (activityType: string, targetId: string, targetName: string): JSX.Element => {
        let url = "";
        let descriptor = "";
        switch (activityType) {
            case "AddDocument":
            case "ViewDocument":
            case "EditDocument":
                url = `$document/${targetId}`;
                descriptor = "Document";
                break;
            case "AssignAdmin":
                url = `user/${targetId}`;
                descriptor = "User";
                break;
            case "Login":
            default:
                return <></>;
        }
        return <>{descriptor}: <a href={url} className="link" style={{ textDecoration: "underline" }}>{targetName}</a></>;

    }

    return (
        <section id="activity-page">
            <div className="title">
                <h1>Activity</h1>
                <div className="controls"></div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} groupable={true} data={activities}>
                    <GridColumn title="First Name" field="firstName" width={15} />
                    <GridColumn title="Last Name" field="lastName" width={15} />
                    <GridColumn title="Organization" field="organization" width={15} />
                    <GridColumn title="Admin" field="isAdminDisplayValue" width={5} />
                    <GridColumn title="Action" field="actionDisplayName" width={15} />
                    <GridColumn
                        title="Applies To"
                        field="targetName"
                        width={25}
                        cell={(props) => {
                            console.log(props);
                            return (<td><span>{getTargetDisplay(props.dataItem.activityType, props.dataItem.targetId, props.dataItem.targetName)}</span></td>);
                        }}
                    />
                    <GridColumn title="Date" field="date" format="{0:g}" width={12} />
                </KendoGrid>
            </div>
        </section>
        )
}

import React, { useEffect, useState, useContext } from "react";
import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAuth0 } from "@auth0/auth0-react";

import useFetch, { FetchResponse } from "../useFetch";

import { Category, ActionType, DispatchContext, ManageItem } from "../state";
import { KendoGrid } from "../kendo-grid";
import useNotification from "../useNotification";

export const CategoriesPage = () => {
    // State
    const { dispatch } = useContext(DispatchContext);
    const [categories, setCategories] = useState<ManageItem[]>([]);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const categoriesApi = useFetch("/api/categories");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            getData();
        }
    }, [isAuthenticated])

    const getData = (): void => {
        categoriesApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    const categories = response.result.categories;
                    setCategories(categories);
                }
            });
    }

    const saveCategory = (category: Category): void => {
        categoriesApi
            .put(category.id, category)
            .then((response: FetchResponse) => {
                handleResponse(response, "Category successfully updated.");
            });
    }

    const deleteCategory = (category: Category): void => {
        categoriesApi
            .del(category.id)
            .then((response: FetchResponse) => {
                handleResponse(response, "Category successfully deleted.");
            });
    }

    const addCategory = (category: Category): void => {
        categoriesApi
            .post(category)
            .then((response: FetchResponse) => {
                handleResponse(response, "Category successfully created.");
            });
    }

    const handleResponse = (response: FetchResponse, successMessage: string): void => {
        if (response.success) {
            notifier.success(successMessage);
            getData();
            dispatch({ type: ActionType.UpdateAppState });
        }
    }

    const sort: SortDescriptor[] = [
        { field: 'name', dir: 'desc' }
    ];

    // Methods
    return (
        <section id="categories-page">
            <div className="title">
                <h1>Categories</h1>
                <div className="controls"></div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} data={categories} inlineEdit={true} save={saveCategory} remove={deleteCategory} add={addCategory}>
                    <GridColumn title="Name" field="name" width={30} />
                    <GridColumn title="Count" field="count" className="column-right" editable={false} width={10} />
                </KendoGrid>
            </div>
        </section>
    );
}
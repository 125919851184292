import React, { useState, FormEvent, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

import { TextArea } from '@progress/kendo-react-inputs';

import { Button } from '@progress/kendo-react-buttons';

import { HtmlTextArea } from "../html-text-area";

import useFetch, { FetchResponse } from "../useFetch";
import useNotification from "../useNotification";

interface SiteContent {
    id: string,
    homeLoggedIn: string;
    homeLoggedOut: string;
    footerLoggedIn: string;
    footerLoggedOut: string;
    requestAccountMessage: string;
    termsOfUse: string;
    termsOfUseCheckboxLabel: string;
    logoAltText: string;
}

export const EditContentPage = (props: any) => {
    // State
    const initialContentState: SiteContent = {
        id: "",
        homeLoggedIn: "",
        homeLoggedOut: "",
        footerLoggedIn: "",
        footerLoggedOut: "",
        requestAccountMessage: "",
        termsOfUse: "",
        termsOfUseCheckboxLabel: "",
        logoAltText: ""
    }

    const [content, setContent] = useState<SiteContent>(initialContentState);

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const contentApi = useFetch("/api/content");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            contentApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const content = response.result;
                        setContent(
                            {
                                id: content.id,
                                homeLoggedIn: content.homeLoggedIn || "",
                                homeLoggedOut: content.homeLoggedOut || "",
                                footerLoggedIn: content.footerLoggedIn || "",
                                footerLoggedOut: content.footerLoggedOut || "",
                                requestAccountMessage: content.requestAccountMessage || "",
                                termsOfUse: content.termsOfUse || "",
                                termsOfUseCheckboxLabel: content.termsOfUseCheckboxLabel || "",
                                logoAltText: content.logoAltText || ""
                            }
                        )
                    }
                });

        }
    }, [isAuthenticated])

    // Methods
    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        contentApi
            .put(content.id, content)
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success("Site content successfully updated");
                }
            });
    }

    const submitDisabled = (): boolean => {
        return false; //ToDo
    }

    return (
        <section id="content-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Site Content</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/") }}>Cancel</Button>
                        <Button disabled={submitDisabled()} primary={true}>Save</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>Home Page (Logged In)</h2>
                        <div>
                            <HtmlTextArea
                                label="Body (HTML)"
                                value={content.homeLoggedIn}
                                onChange={(value: string) => setContent({ ...content, homeLoggedIn: value })}
                            />
                        </div>

                        <h2>Home Page (Logged Out)</h2>
                        <div>
                            <HtmlTextArea
                                label="Request Account Box (HTML)"
                                value={content.requestAccountMessage}
                                onChange={(value: string) => setContent({ ...content, requestAccountMessage: value })}
                            />
                        </div>
                        <div>
                            <HtmlTextArea
                                label="Body (HTML)"
                                value={content.homeLoggedOut}
                                onChange={(value: string) => setContent({ ...content, homeLoggedOut: value })}
                            />
                        </div>

                        <h2>Site Footer (Logged In)</h2>
                        <div>
                            <HtmlTextArea
                                label="Footer (HTML)"
                                value={content.footerLoggedIn}
                                onChange={(value: string) => setContent({ ...content, footerLoggedIn: value })}
                            />
                        </div>

                        <h2>Site Footer (Logged Out)</h2>
                        <div>
                            <HtmlTextArea
                                label="Footer (HTML)"
                                value={content.footerLoggedOut}
                                onChange={(value: string) => setContent({ ...content, footerLoggedOut: value })}
                            />
                        </div>

                        <h2>Request Account</h2>
                        <div>
                            <HtmlTextArea
                                label="Terms Of Use (HTML)"
                                value={content.termsOfUse}
                                onChange={(value: string) => setContent({ ...content, termsOfUse: value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="tou-checkbox">Terms Of Use Checkbox Text (HTML)</label>
                            <TextArea
                                id="tou-checkbox"
                                name="tou-checkbox"
                                rows={1}
                                autoSize={true}
                                value={content.termsOfUseCheckboxLabel}
                                onChange={(e: any) => setContent({ ...content, termsOfUseCheckboxLabel: e.value }) }
                            />
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}
import { useContext } from "react";

import { DispatchContext, ActionType } from "./state";

const useNotification = () => {
    const { dispatch } = useContext(DispatchContext);

    const success = (message: string): void => {
        dispatch({ type: ActionType.SuccessNotification, message: message });
    }

    const error = (message: string): void => {
        dispatch({ type: ActionType.ErrorNotification, message: message });
    }

    return {
        success,
        error
    };
};

export default useNotification;
import React, { useEffect, useState, useContext } from "react";
import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAuth0 } from "@auth0/auth0-react";

import useFetch, { FetchResponse } from "../useFetch";
import { Group, ActionType, DispatchContext, ManageItem } from "../state";
import { KendoGrid } from "../kendo-grid";
import useNotification from "../useNotification";

export const GroupsPage = () => {
    // State
    const { dispatch } = useContext(DispatchContext);
    const [groups, setGroups] = useState<ManageItem[]>([]);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const groupsApi = useFetch("/api/groups");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            getData();
        }
    }, [isAuthenticated])

    const getData = (): void => {
        groupsApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    const groups = response.result.groups;
                    setGroups(groups);
                }
            });
    }

    const saveGroup = (group: Group): void => {
        groupsApi
            .put(group.id, group)
            .then((response: FetchResponse) => {
                handleResponse(response, "Group successfully updated.");
            });
    }

    const deleteGroup = (group: Group): void => {
        groupsApi
            .del(group.id)
            .then((response: FetchResponse) => {
                handleResponse(response, "Group successfully deleted.");
            });
    }

    const addGroup = (group: Group): void => {
        groupsApi
            .post(group)
            .then((response: FetchResponse) => {
                handleResponse(response, "Group successfully created.");
            });
    }

    const handleResponse = (response: FetchResponse, successMessage: string): void => {
        if (response.success) {
            notifier.success(successMessage);
            getData();
            dispatch({ type: ActionType.UpdateAppState });
        }
    }

    const sort: SortDescriptor[] = [
        { field: 'name', dir: 'desc' }
    ];

    // Methods
    return (
        <section id="groups-page">
            <div className="title">
                <h1>Groups</h1>
                <div className="controls"></div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} data={groups} inlineEdit={true} save={saveGroup} remove={deleteGroup} add={addGroup}>
                    <GridColumn title="Name" field="name" width={30} />
                    <GridColumn title="Count" field="count" className="column-right" editable={false} width={10} />
                </KendoGrid>
            </div>
        </section>
    );
}
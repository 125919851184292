import React, { useEffect, useState, FormEvent, useContext } from "react";
import { useHistory } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { KendoGrid } from "./kendo-grid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import useFetch, { FetchResponse } from "./useFetch";
import useNotification from "./useNotification";

import { RequiredLabel } from "./required-label";

import { Document, buildDocument, Group, AppStateContext } from "./state";
import { HtmlTextArea } from "./html-text-area";

interface EmailNotification {
    documentIds: string[],
    fromName: string,
    fromEmail: string,
    groups: Group[],
    groupIds: string[],
    subject: string,
    message: string,
    isHtml: boolean
}

export const SendEmailNotificationPage = (props: any) => {
    // State
    const { groups } = useContext(AppStateContext);

    const initialNotificationState: EmailNotification = {
        documentIds: [],
        fromName: "",
        fromEmail: "",
        groups: [],
        groupIds: [],
        subject: "",
        message: "",
        isHtml: false
    }

    const initialSelectedDocumentIdsState: string[] = [];

    const [notification, setNotification] = useState(initialNotificationState);
    const [showDocumentSelector, setShowDocumentSelector] = useState(false);
    const [allDocuments, setAllDocuments] = useState<Document[]>([]);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState(initialSelectedDocumentIdsState);

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const documentsApi = useFetch("/api/documents/published");
    const emailApi = useFetch("/api/email");
    const configApi = useFetch("/api/config");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            documentsApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const documents = buildDocuments(response.result.documents);
                        setAllDocuments(documents);
                        setDocuments(documents);
                    }
                });

            configApi
                .get()
                .then((response: FetchResponse) => {
                    setNotification({
                        ...notification,
                        fromName: response.result.emailFromName || "",
                        fromEmail: `support@${response.result.emailDomain}`
                    })
                });
        }
    }, [isAuthenticated])

    // Methods
    const buildDocuments = (documentResponse: any[]): Document[] => {
        return documentResponse.map<Document>(documentResponse => {
            return buildDocument(documentResponse);
        });
    };

    const documentInGroups = (document: Document, groupIds: string[]): boolean => {
        if (document.groupIds.length === 0) { return true }
        let documentInGroups: boolean = false;
        groupIds.forEach((groupId: string) => {
            if (document.groupIds.indexOf(groupId) >= 0) {
                documentInGroups = true;
            }
        });
        return documentInGroups;
    }

    const submitDisabled = (): boolean => {
        return !notification.subject || !notification.message || notification.documentIds.length === 0 || notification.groupIds.length === 0;
    }

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        emailApi
            .post(notification)
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success("Notification emails will be sent shortly");
                    history.push("/");
                }
            });
    }

    const updateGroups = (groups: Group[]): void => {
        const groupIds = groups.map(g => g.id);
        setNotification({ ...notification, groups: groups, groupIds: groupIds });

        //Filter documents - only show documents in selected groups
        if (groupIds.length === 0) {
            setDocuments(allDocuments);
        }
        else {
            const filteredDocuments = allDocuments
                .filter((document: Document) => documentInGroups(document, groupIds));
            setDocuments(filteredDocuments);
        }
    }

    const updateDocuments = (): void => {
        setNotification({ ...notification, documentIds: selectedDocumentIds })
        setShowDocumentSelector(false);
    }

    const handleDocumentSelect = (selected: boolean, documentId: string): void => {
        if (selected) {
            setSelectedDocumentIds([...selectedDocumentIds, documentId]);
        }
        else {
            setSelectedDocumentIds(selectedDocumentIds.filter(i => i !== documentId));
        }
    }

    const selectedDocumentsList: JSX.Element[] = notification
        .documentIds
        .map((documentId: string) => {
            const document: Document = allDocuments.filter(d => d.id === documentId)[0];
            return (
                <tr key={document.id} className={!documentInGroups(document, notification.groupIds) ? "warn" : ""}>
                    <td>{document.date?.toLocaleDateString("en-US")}</td>
                    <td>{document.title}</td>
                    <td>{document.categories.map((c: any) => c.name).join(", ")}</td>
                    <td>{document.groups.length === 0 ? "All" : document.groups.map((g: any) => g.name).join(", ")}</td>
                    <td style={{ textAlign: "right" }}>
                        <span className="k-icon k-i-delete link" onClick={() => { setNotification({ ...notification, documentIds: notification.documentIds.filter(i => i !== document.id) }) }}></span>
                    </td>
                </tr>
            )
        });

    const sort: SortDescriptor[] = [
        { field: 'date', dir: 'desc' }
    ];

    return (
        <section id="notifications-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Send Email Notification</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/") }}>Cancel</Button>
                        <Button disabled={submitDisabled()} primary={true}>Send</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>Message Properties</h2>
                        <div>
                            <label htmlFor="sender">From</label>
                            <Input id="sender" name="sender" disabled={true} value={`${notification.fromName} (${notification.fromEmail})`} />
                        </div>
                        <div>
                            <RequiredLabel htmlFor="groups" field={notification.groupIds.join(",")}>To</RequiredLabel>
                            <div>
                                <MultiSelect
                                    id="groups"
                                    name="groups"
                                    data={groups}
                                    onChange={(e) => { updateGroups(e.target.value) }}
                                    value={notification.groups}
                                    textField="name"
                                    dataItemKey="id"
                                    style={{ marginTop: ".5rem" }}
                                />
                            </div>
                        </div>
                        <div>
                            <RequiredLabel htmlFor="subject" field={notification.subject}>Subject</RequiredLabel>
                            <Input
                                id="subject"
                                name="subject"
                                onChange={(e) => setNotification({ ...notification, subject: e.target.value?.toString() ?? "" })}
                                value={notification.subject} />
                        </div>
                        <div>
                            <HtmlTextArea
                                label="Message"
                                value={notification.message}
                                onChange={(value: string) => setNotification({ ...notification, message: value })}
                            />
                        </div>

                        <h2>Documents</h2>

                        {notification.documentIds.length === 0 &&
                            <div>Select documents to continue</div>
                        }

                        <Button onClick={(e) => { e.preventDefault(); setSelectedDocumentIds(notification.documentIds); setShowDocumentSelector(true); }} >Select Documents</Button>

                        {notification.documentIds.length > 0 &&
                            <table className="notification-document-list">
                                <thead>
                                    <tr>
                                        <th style={{width: "7rem" }}>Date</th>
                                        <th>Title</th>
                                        <th>Categories</th>
                                        <th>Groups</th>
                                        <th style={{ width: "2.5rem" }}> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDocumentsList}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>

                {showDocumentSelector &&
                    <Dialog title="Select Documents" onClose={() => { setShowDocumentSelector(false) }} width={"80%"}>
                        <KendoGrid sort={sort} data={documents}>
                            <GridColumn title=" " field="id" width={5}
                                filterable={false}
                                cell={(props) => (
                                    <td className={!documentInGroups(props.dataItem, notification.groupIds) ? "warn" : ""} style={{ textAlign: "center" }}>
                                            <Checkbox
                                                checked={selectedDocumentIds.indexOf(props.dataItem.id) >= 0}
                                                onChange={(e) => { handleDocumentSelect(e.value, props.dataItem.id) }}
                                            />
                                        </td>
                                    )} />
                            <GridColumn title="Date" field="date" format="{0:MM/dd/yy}" width={15} />
                            <GridColumn title="Title" field="title" width={25} />
                            <GridColumn title="Categories" field="categories" width={20}
                                cell={(props) => (<td>{props.dataItem.categories.map((c: any) => c.name).join(", ")}</td>)} />
                            <GridColumn title="Groups" field="groups" width={20}
                                cell={(props) => (<td>{props.dataItem.groups.length === 0 ? "All" : props.dataItem.groups.map((g: any) => g.name).join(", ")}</td>)} />
                        </KendoGrid>

                        <DialogActionsBar>
                            <Button onClick={(e) => { e.preventDefault(); setShowDocumentSelector(false); }}>Cancel</Button>
                            <Button onClick={(e) => { e.preventDefault(); updateDocuments(); }} primary={true} disabled={ selectedDocumentIds.length === 0 }>Add Selected</Button>
                        </DialogActionsBar>
                    </Dialog>
                }
            </form>
        </section>
    );
}
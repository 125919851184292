import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";

import { DropDownList } from '@progress/kendo-react-dropdowns';

import { useAuth0 } from "@auth0/auth0-react";

import { TenantContext, DispatchContext, TenantActionType } from "./state";

export const TenantSelect = (props: any) => {
    const { tenantDispatch } = useContext(DispatchContext);
    const tenantState = useContext(TenantContext);
    const history = useHistory();

    const [selectedTenantOption, setSelectedTenantOption] = useState<{ name: string, displayName: string }>({ name: "", displayName: "" });

    const { isAuthenticated } = useAuth0();

    const setCurrentTenant = (event: any): void => {
        const selectedOption: any = event.target.value
        const tenantName = selectedOption.name;
        if (tenantName) {
            setSelectedTenantOption(selectedOption);
            tenantDispatch({
                type: TenantActionType.SetCurrentTenant,
                tenantName: tenantName
            });

            localStorage.setItem("doc-manager-tenant", tenantName);
        }
    }

    useEffect(() => {
        if (tenantState.currentTenant) {
            setSelectedTenantOption(tenantState.currentTenant);
        }

        if (tenantState.tenantChanged) {
            console.log("tenantState changed, pushing new history");
            history.push("/");
        }
    }, [tenantState]);

    return (
        <>
            {isAuthenticated && tenantState.tenants &&
                <DropDownList
                    data={tenantState.tenants}
                    textField="displayName"
                    dataItemKey="name"
                    value={selectedTenantOption}
                    onChange={(e) => { setCurrentTenant(e) }}
                    style={{ width: "14.5rem"}}/>
            }
        </>
    );
}
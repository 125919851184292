import React, { useState, FunctionComponent, useContext } from "react";

import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import useFetch, { FetchResponse } from "./useFetch";
import { ActionType, DispatchContext } from "./state";

interface MultiSelectWithCreateProps {
    name: string
    data: any[],
    value: any,
    onChange: any,
    onCreate: any
}

export const MultiSelectWithCreate: FunctionComponent<MultiSelectWithCreateProps> = ({ name, data, value, onChange, onCreate }) => {
    const { dispatch } = useContext(DispatchContext);

    const [createMode, setCreateMode] = useState(false);
    const [createName, setCreateName] = useState("");
    const [loading, setLoading] = useState(false);

    const api = useFetch(`/api/${name}`);

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        api
            .post({ id: "", name: createName })
            .then((response: FetchResponse) => {
                let values = [...value];
                values.push({ id: response.result, name: createName });
                onCreate(values);

                dispatch({ type: ActionType.UpdateAppState });
                setCreateMode(false);
                setLoading(false);
            });
    };

    const cancel = (event: any) => {
        event.preventDefault();
        setCreateMode(false);
    };

    return (
        <div style={{position: "relative"}}>
            <MultiSelect
                id={`${name}`}
                name={`${name}`}
                data={data}
                onChange={onChange}
                value={value}
                textField="name"
                dataItemKey="id"
            />
            {!createMode && <Button onClick={(e) => { e.preventDefault(); setCreateName(""); setCreateMode(true); }} style={{ marginLeft: ".5rem" }} >[+]</Button>}
            {createMode &&
                <div style={{ position: "absolute", backgroundColor: "#fff", padding: "0.5rem 1rem", border: "1px solid rgba(0, 0, 0, 0.08)", zIndex: 999 }}>
                    <label htmlFor={`${name}-create`}>
                        Enter a new {data[0]?.itemType.toLowerCase() || "value"} to create {data.find(d => d.name === createName) &&
                            <span className="required">({data[0]?.itemType || "Value"} already exists)</span>}
                    </label>
                    <Input
                        id={`${name}-create`}
                        name={`${name}-create`}
                        disabled={loading}
                        onChange={(e) => { setCreateName(e.value?.toString() || "") }}
                        value={createName}
                    />
                    {loading &&
                        <span className="k-icon k-i-loading" style={{ position: "absolute", left: "0", bottom: "1.2rem", width: "100%", textAlign: "center" }}></span>
                    }
                    <Button onClick={save} primary={true} disabled={loading || !createName || data.find(d => d.name === createName) } style={{ marginTop: ".5rem", marginLeft: ".5rem" }}>Save</Button>
                    <Button onClick={cancel} disabled={loading} style={{marginTop: ".5rem", marginLeft: ".5rem"}}>Cancel</Button>
                </div>}
        </div>
    );
}

import React, { useEffect, useState, useContext } from "react";
import { GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAuth0 } from "@auth0/auth0-react";

import useFetch, { FetchResponse } from "../useFetch";

import { Tag, ActionType, DispatchContext, ManageItem } from "../state";
import { KendoGrid } from "../kendo-grid";
import useNotification from "../useNotification";

export const TagsPage = () => {
    // State
    const { dispatch } = useContext(DispatchContext);
    const [tags, setTags] = useState<ManageItem[]>([]);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const tagsApi = useFetch("/api/tags");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            getData();
        }
    }, [isAuthenticated])

    const getData = (): void => {
        tagsApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    const tags = response.result.tags;
                    setTags(tags);
                }
            });
    }

    const saveTag = (tag: Tag): void => {
        tagsApi
            .put(tag.id, tag)
            .then((response: FetchResponse) => {
                handleResponse(response, "Tag successfully updated.");
            });
    }

    const deleteTag = (tag: Tag): void => {
        tagsApi
            .del(tag.id)
            .then((response: FetchResponse) => {
                handleResponse(response, "Tag successfully deleted.");
            });
    }

    const addTag = (tag: Tag): void => {
        tagsApi
            .post(tag)
            .then((response: FetchResponse) => {
                handleResponse(response, "Tag successfully created.");
            });
    }

    const handleResponse = (response: FetchResponse, successMessage: string): void => {
        if (response.success) {
            notifier.success(successMessage);
            getData();
            dispatch({ type: ActionType.UpdateAppState });
        }
    }

    const sort: SortDescriptor[] = [
        { field: 'name', dir: 'desc' }
    ];

    // Methods
    return (
        <section id="tags-page">
            <div className="title">
                <h1>Tags</h1>
                <div className="controls"></div>
            </div>
            <div className="page">
                <KendoGrid sort={sort} data={tags} inlineEdit={true} save={saveTag} remove={deleteTag} add={addTag}>
                    <GridColumn title="Name" field="name" width={30} />
                    <GridColumn title="Count" field="count" className="column-right" editable={false} width={10} />
                </KendoGrid>
            </div>
        </section>
    );
}
import React, { useEffect, useState, FormEvent, useContext } from "react";
import { useHistory } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { Upload } from '@progress/kendo-react-upload';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input, TextArea  } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RadioButton } from '@progress/kendo-react-inputs';

import { MultiSelectWithCreate } from "./multi-select-create";

import useFetch, { FetchResponse } from "./useFetch";
import useNotification from "./useNotification";

import { Document, TenantContext, FileType, AppStateContext, DocumentStatus, buildDocument, Group, Category, Tag } from "./state";

export const EditDocumentPage = (props: any) => {
    // State
    const tenantState = useContext(TenantContext);

    const { categories, tags, groups } = useContext(AppStateContext);

    const initialDocumentState: Document = {
        id: "",
        title: "",
        description: "",
        fileName: "",
        fileType: FileType.PDF,
        originalFileName: "",
        fileStatus: "",
        status: DocumentStatus.Unpublished,
        categories: [],
        tags: [],
        groups: [],
        groupIds: [],
        date: new Date(),
        viewCount: 0,
        createdByUserName: "",
        createdDate: new Date(),
        modifiedByUserName: "",
        modifiedDate: new Date(),
        lastViewUserName: "",
        lastViewDate: new Date()
    }

    const [editDocument, setEditDocument] = useState(initialDocumentState);
    const [groupsOption, setGroupsOption] = useState("All");
    const [editMode, setEditMode] = useState("create");
    const [token, setToken] = useState("");
    const [files, setFiles] = useState([]);

    const history = useHistory();

    // Auth0
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    // API
    const documentsApi = useFetch("/api/documents");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            getToken();

            const id: string = props.match.params.id;
            if (id.toLowerCase() !== "create") {
                setEditMode("edit");
                documentsApi
                    .get(id)
                    .then((response: FetchResponse) => {
                        if (response.success) {
                            const documentResponse = response.result.document;
                            const document = buildDocument(documentResponse);
                            setEditDocument(document);

                            if (document.groups.length === 0) {
                                setGroupsOption("All");
                            }
                            else {
                                setGroupsOption("Custom");
                            }
                        }
                    });
            }
        }
    }, [isAuthenticated]);

    // Methods
    const getToken = async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
    };

    const deleteDocument = (): void => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            documentsApi
                .del(editDocument.id)
                .then((response: FetchResponse) => {
                    if (response.success) {
                        notifier.success("Document successfully deleted");
                        history.push("/");
                    }
                })
        }
    }

    const submitDisabled = (): boolean => {
        return !(editDocument.categories.length > 0 && editDocument.date && editDocument.fileName && editDocument.title);
    }

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        if (editMode === "create") {
            documentsApi
                .post(editDocument)
                .then((response: FetchResponse) => {
                    if (response.success) {
                        notifier.success("Document successfully created");

                        setEditMode("edit");

                        const id = response.result;
                        setEditDocument({...editDocument, id: id });

                        history.push(`/document/${id}`);
                    }
                })
        }
        else {
            documentsApi
                .put(editDocument.id, editDocument)
                .then((response: FetchResponse) => {
                    if (response.success) {
                        notifier.success("Document successfully updated");
                    }
                })
        }
    }

    const updateGroups = (groups: Group[]): void => {
        const groupIds = groups.map(g => g.id);
        setEditDocument({ ...editDocument, groups: groups, groupIds: groupIds });
    }

    // Files
    const removeFile = (): void => {
        setFiles([]);
        setEditDocument({ ...editDocument, fileName: "" });
    }

    const onBeforeUpload = (event: any) => {
        const extension = event.files[0].extension;
        const videoExtensions = [".m4a", ".mp4", ".mkv", ".m4v", ".mp5", ".webm", ".mov", ".mpg", ".mpeg"];
        if (extension === ".pdf") {
            setEditDocument({ ...editDocument, fileType: FileType.PDF });
        }
        else if (extension === ".doc" || extension === ".docx") {
            setEditDocument({ ...editDocument, fileType: FileType.WORD });
        }
        else if (extension === ".xls" || extension === ".xlsx") {
            setEditDocument({ ...editDocument, fileType: FileType.EXCEL });
        }
        else if (extension === ".ppt" || extension === ".pptx") {
            setEditDocument({ ...editDocument, fileType: FileType.POWERPOINT });
        }
        else if (videoExtensions.indexOf(extension) != -1) {
            setEditDocument({ ...editDocument, fileType: FileType.VIDEO, fileStatus: "Processing" });
        }

        if (isAuthenticated) {
            event.headers = {
                ...event.headers,
                Authorization: `Bearer ${token}`,
                "TenantName": tenantState?.currentTenant?.name
            };
        }
    }

    const onStatusChange = (event: any): void => {
        setFiles(event.newState);
        if (event.response) {
            const response = event.response.response;
            if (response) {
                setEditDocument({ ...editDocument, fileName: response.fileName, originalFileName: response.originalFileName });
            }
        }
    }

    const onProgress = (event: any) => {
        setFiles(event.newState);
    };

    const onAdd = (event: any) => {
        setFiles(event.newState);
    };

    const onRemove = (event: any) => {
        removeFile();
    };

    const getFilePath = (): string => {
        if (editMode === "create") {
            return `api/file/download/temp/${editDocument.fileName}`;
        }

        return `api/file/download/${editDocument.fileName}`;
    }

    const downloadFetch = async () => {
        // ToDo There has to be a better way to do this
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();

            fetch(getFilePath(), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "TenantName": tenantState?.currentTenant?.name || ""
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = editDocument.originalFileName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        }
    }

    const downloadDocument = async (event: any) => {
        event.preventDefault();
        await downloadFetch();
    }

    return (
        <section id="document-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Edit Document</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/") }}>Cancel</Button>
                        <Button disabled={submitDisabled()} primary={true}>Save</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>Contents</h2>
                        <div>
                            <label htmlFor="date">Date</label>
                            <DatePicker
                                id="date"
                                name="date"
                                value={editDocument.date}
                                onChange={(e: any) => { setEditDocument({ ...editDocument, date: e.target.value }) }}/>
                        </div>
                        <div>
                            <label htmlFor="title">Title</label>
                            <Input
                                id="title"
                                name="title"
                                onChange={(e: any) => setEditDocument({ ...editDocument, title: e.target.value?.toString() || "" })}
                                value={editDocument.title} />
                        </div>
                        <div>
                            <label htmlFor="description">Description</label>
                            <TextArea
                                id="description"
                                name="description"
                                value={editDocument.description}
                                onChange={(e: any) => setEditDocument({ ...editDocument, description: e.value?.toString() || "" })}
                                autoSize={true}
                                rows={4}
                            />
                        </div>

                        <div>
                            <label>File</label>
                            {editDocument.fileName &&
                                <>
                                    {editDocument.fileType !== FileType.VIDEO &&
                                        <a href={getFilePath()} onClick={(e) => downloadDocument(e)} download style={{ textDecoration: "underline" }}>{editDocument.originalFileName}</a>
                                    }
                                    {editDocument.fileType === FileType.VIDEO &&
                                        <>{ editDocument.originalFileName }</>
                                    }
                                    <Button onClick={(e) => { removeFile() }} style={{ marginLeft: "1rem" }}>Change File</Button>
                                    {editDocument.fileType === FileType.VIDEO &&
                                        <span style={{ fontSize: ".8rem", marginLeft: "1rem" }}>Video Status: {editDocument.fileStatus}</span>
                                    }
                                </>
                            }

                            {!editDocument.fileName &&
                                <Upload
                                    files={files}
                                    batch={false}
                                    multiple={false}
                                    withCredentials={true}
                                    onBeforeUpload={onBeforeUpload}
                                    onStatusChange={onStatusChange}
                                    onProgress={onProgress}
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    restrictions={{
                                        allowedExtensions: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.m4a', '.mp4', '.mkv', '.m4v', '.mp5', '.webm', '.mov', '.mpg', '.mpeg']
                                    }}
                                    saveUrl={'/api/file/upload'} />
                            }
                        </div>

                        <div>
                            <label htmlFor="categories">Categories</label>
                            <MultiSelectWithCreate
                                name="categories"
                                data={categories}
                                value={editDocument.categories}
                                onChange={(e: any) => { setEditDocument({ ...editDocument, categories: e.target.value }) }}
                                onCreate={(updatedCategories: Category[]) => { setEditDocument({ ...editDocument, categories: updatedCategories }); } }
                            />
                        </div>

                        <div>
                            <label htmlFor="tags">Tags (Optional)</label>
                            <MultiSelectWithCreate
                                name="tags"
                                data={tags}
                                value={editDocument.tags}
                                onChange={(e: any) => { setEditDocument({ ...editDocument, tags: e.target.value }) }}
                                onCreate={(updatedTags: Tag[]) => { setEditDocument({ ...editDocument, tags: updatedTags }); }}
                            />
                        </div>

                        <h2>Settings</h2>

                        <div>
                            <label htmlFor="status">Status</label>
                            <DropDownList
                                data={[DocumentStatus.Unpublished, DocumentStatus.Published, DocumentStatus.Archived]}
                                value={editDocument.status}
                                onChange={(e) => { setEditDocument({ ...editDocument, status: e.target.value }) }}
                            />
                        </div>

                        <div>
                            <label>Access</label>
                            <div>
                                <RadioButton
                                    label="All Groups"
                                    name="access"
                                    value="all"
                                    checked={groupsOption === "All"}
                                    onChange={(e: any) => { setGroupsOption("All"); updateGroups([]); }} />
                                <RadioButton
                                    label="Custom"
                                    name="access"
                                    value="custom"
                                    checked={groupsOption === "Custom"}
                                    onChange={(e: any) => { setGroupsOption("Custom");  updateGroups(editDocument.groups); }} style={{ marginLeft: "1rem" }} />

                                {groupsOption === "Custom" &&
                                    <MultiSelectWithCreate
                                        name="groups"
                                        data={groups}
                                        value={editDocument.groups}
                                        onChange={(e: any) => { updateGroups(e.target.value) }}
                                        onCreate={(updatedGroups: Group[]) => { updateGroups(updatedGroups); }}
                                    />
                                }
                            </div>
                        </div>

                        {editMode === "edit" &&
                            <>
                                <h2>Properties</h2>
                                <div className="properties">
                                    <div>Created:</div>
                                    {editDocument.createdByUserName} ({editDocument.createdDate.toLocaleDateString()})

                                    <div>Last Modified:</div>
                                    {editDocument.modifiedByUserName} ({editDocument.modifiedDate.toLocaleDateString()})

                                    <div>Last Viewed:</div>
                                    {editDocument.lastViewUserName ? <>{editDocument.lastViewUserName} ({editDocument.lastViewDate.toLocaleDateString()})</> : "N/A"}

                                    <div>View Count:</div>
                                    {editDocument.viewCount}
                                </div>

                                <Button className="delete" onClick={(e) => { e.preventDefault(); deleteDocument() }}>Delete this Document</Button>
                            </>
                        }
                    </div>
                </div>
            </form>
        </section>
    );
}
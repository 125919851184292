import React, { useContext } from "react";
import { AppStateContext } from "./state";

export const LoadingIndicator = () => {
    const { loading } = useContext(AppStateContext);

    return (<>
        {loading &&
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        }
    </>);
}

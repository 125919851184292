import React, { useState, useEffect, FunctionComponent } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { DataResult, State, process, SortDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";

import { KendoColumnMenu } from "./kendo-column-menu";

interface KendoColumn {
    field: string,
    title: string,
    width: number,
    show: boolean,
    props: any
}

interface KendoGridProps {
    selectedFields?: string[],
    sort: SortDescriptor[],
    filter?: CompositeFilterDescriptor,
    groupable?: boolean,
    data: any[],
    onEdit?: Function,
    inlineEdit?: boolean,
    save?: any,
    remove?: any,
    add?: any
    children?: any[]
}

export const KendoGrid: FunctionComponent<KendoGridProps> = ({ selectedFields, sort, filter, groupable, data, onEdit, inlineEdit, save, remove, add, children }) => {
    const initialState: { dataResult: DataResult, dataState: State } = {
        dataResult: {
            data: data,
            total: 0
        },
        dataState: {
            skip: 0,
            filter: filter,
            take: 20,
            sort: sort,
            group: []
        }
    };

    const initialColumns: KendoColumn[] = [];

    const commandColumnWidth: number = 9;

    const [state, setState] = useState(initialState);
    const [gridWidth, setGridWidth] = useState(0);
    const [columnsWidth, setColumnsWidth] = useState(0);
    const [columns, setColumns] = useState(initialColumns);
    const [selectedColumns, setSelectedColumns] = useState(initialColumns);

    const calculateGridWidth = (): void => {
        setGridWidth(document.querySelector('.k-grid-header-wrap')?.clientWidth || 0);
    }

    window.addEventListener("resize", calculateGridWidth);

    //Effects
    useEffect(() => {
        if (data) {
            const gridData = data.map(item => { return { ...item, inEdit: false } });
            const dataResult = process(gridData, state.dataState);
            setState({ ...state, dataResult: dataResult });
        }
    }, [data])

    useEffect(() => {
        let columns: KendoColumn[] = [];
        if (children) {
            columns = children.map(c => {
                return {
                    field: c.props.field,
                    title: c.props.title,
                    width: c.props.width,
                    show: selectedFields ? selectedFields.indexOf(c.props.field) >= 0 : true,
                    props: c.props
                }
            });
        }

        setColumnState(columns);
    }, [children])

    useEffect(() => {
        calculateGridWidth();
    }, []);

    // Grid Operations
    const dataStateChange = (event: any): void => {
        console.log(event);
        setState({
            dataResult: process(data, event.dataState),
            dataState: event.dataState
        });
    }

    const addNewItem = (): void => {
        const dataResult = process(resetData(), state.dataState);

        const addData = [{ inEdit: true }, ...dataResult.data];
        setState({
            ...state,
            dataResult: {
                data: addData,
                total: state.dataResult.total + 1
            }
        });
    }

    const editItem = (dataItem: any): void => {
        const editData = resetData().map((item: any) => {
            return  item.id === dataItem.id ? { ...item, inEdit: true } : item
        });
        const dataResult = process(editData, state.dataState);
        setState({ ...state, dataResult: dataResult });
    }

    const cancelEditItem = (): void => {
        const dataResult = process(resetData(), state.dataState);
        setState({ ...state, dataResult: dataResult });
    }

    const resetData = (): any[] => {
        return data
            .map((item: any) => {
                return { ...item, inEdit: false }
            })
            .filter(item => item.id);
    }

    const deleteItem = (dataItem: any): void => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            remove(dataItem)
        }
    }

    const saveItem = (dataItem: any): void => {
        if (dataItem.id) {
            save(dataItem);
        }
        else {
            add(dataItem);
        }
    }

    const itemChange = (event: any): void => {
        const editData = state.dataResult.data.map((item: any) => {
            return item.id === event.dataItem.id ? { ...item, [event.field]: event.value } : item;
        });
        setState({
            ...state,
            dataResult: {
                data: editData,
                total: state.dataResult.total
            }
        });
    }

    const expandChange = (event: any) => {
        event.dataItem[event.target.props.expandField] = event.value;
        setState({
            dataResult: { ...state.dataResult},
            dataState: state.dataState
        });
    }

    //Columns
    const buildColumns = (): JSX.Element[] => {
        calculateColumnsWidth(selectedColumns.map(c => {
            if (!c.width) {
                console.warn("width not set for column", c.title)
                return 5;
            }
            return c.width
        }));

        return selectedColumns
            .map((c, i) => {
                const props = {
                    ...c.props,
                    width: setWidth(c.width)
                };
                return (<GridColumn key={i} columnMenu={buildColumnMenu} {...props} />);
            })
    }

    const buildColumnMenu = (menuProps: any) => {
        return (
            <KendoColumnMenu
                {...menuProps}
                columns={columns}
                onColumnsSubmit={setColumnState}
            />
        )
    }

    const setColumnState = (columns: KendoColumn[]): void => {
        setColumns(columns);
        setSelectedColumns(columns.filter(c => c.show));
    }

    //Column Widths
    const setWidth = (columnWidth: number): number => {
        let actualGridWidth: number = gridWidth;
        if (state.dataState.group) {
            actualGridWidth -= (28 * state.dataState.group.length); // When grouped remove space from collapse control
        }

        return (columnWidth / columnsWidth) * actualGridWidth;
    }

    const calculateColumnsWidth = (widths: number[]): void => {
        let total = (inlineEdit || onEdit) ? commandColumnWidth : 0;
        widths.forEach(w => total += w);
        if (columnsWidth === 0 || total !== columnsWidth) {
            setColumnsWidth(total);
        }
    }

    return (
        <>
            <Grid
                className="grid"
                data={state.dataResult}
                editField="inEdit"
                onItemChange={itemChange}
                sortable
                groupable={groupable !== undefined ? groupable : false}
                pageable={{ buttonCount: 6, pageSizes: [20, 50, 100, 500] }}
                {...state.dataState}
                onDataStateChange={dataStateChange}
                onExpandChange={expandChange}
                expandField="expanded"
                resizable
            >
                {inlineEdit &&
                    <GridToolbar>
                        <Button
                            style={{float: "right"}}
                            primary={true}
                            onClick={addNewItem}
                        >
                        Create
                        </Button>
                    </GridToolbar>
                }
                {buildColumns()}
                {inlineEdit &&
                    <GridColumn
                        title=" "
                        field="id"
                        width={setWidth(commandColumnWidth)}
                        cell={(props) => (
                            <td className="column-right">
                                {!props.dataItem.inEdit && <Button onClick={(e) => editItem(props.dataItem)} primary={true}>Edit</Button>}
                                {!props.dataItem.inEdit && <Button onClick={(e) => deleteItem(props.dataItem)}>Delete</Button>}

                                {props.dataItem.inEdit && <Button onClick={(e) => saveItem(props.dataItem)} primary={true}>Save</Button>}
                                {props.dataItem.inEdit && <Button onClick={(e) => cancelEditItem()}>Cancel</Button>}
                            </td>)}
                    />
                }
                {!inlineEdit && onEdit &&
                    <GridColumn
                        title=" "
                        field="id"
                        width={setWidth(commandColumnWidth)}
                        cell={(props) => {
                            if (props.rowType === "groupHeader") {
                                return null;
                            }
                            return (
                                <td className="column-right">
                                    <Button onClick={(e) => onEdit(props.dataItem["id"])} primary={true}>Edit</Button>
                                </td>
                            );
                        }}
                    />

                }
                </Grid>
            </>
        );
}
import React, { useEffect, useState, FormEvent, useContext } from "react";
import { useHistory } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { MultiSelect } from '@progress/kendo-react-dropdowns';

import useFetch, { FetchResponse } from "./useFetch";
import useNotification from "./useNotification";

import { User, UserStatus, Group, AppStateContext } from "./state";
import { MultiSelectWithCreate } from "./multi-select-create";

export const EditUserPage = (props: any) => {
    // State
    const { groups } = useContext(AppStateContext);

    const initialUserState: User = {
        id: "",
        firstName: "",
        lastName: "",
        createdDate: new Date(),
        emailAddress: "",
        status: UserStatus.Inactive,
        emailVerified: false,
        organization: "",
        groups: [],
        groupIds: [],
        isAdmin: false,
        lastLogin: new Date(),
        loginCount: 0
    }

    const [user, setUser] = useState(initialUserState);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);
    const [changePasswordSent, setChangePasswordSent] = useState(false);

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const usersApi = useFetch("/api/users");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            const id: string = props.match.params.id;
            if (id) {
                usersApi
                    .get(id)
                    .then((response: FetchResponse) => {
                        if (response.success) {
                            const userResponse = response.result.user;
                            const user = {
                                id: userResponse.id,
                                firstName: userResponse.firstName,
                                lastName: userResponse.lastName,
                                createdDate: new Date(userResponse.createdDate),
                                emailAddress: userResponse.emailAddress,
                                status: userResponse.status, //ToDo Sort out status
                                emailVerified: userResponse.emailVerified,
                                organization: userResponse.organization || "",
                                groups: userResponse.groups,
                                groupIds: userResponse.groupIds,
                                isAdmin: userResponse.isAdmin,
                                lastLogin: new Date(userResponse.lastLogin),
                                loginCount: userResponse.loginCount
                            };
                            setUser(user);
                        }
                    });
            }
        }
    }, [isAuthenticated]);

    const emailVerifiedDropDownOptions = [{ text: "Verified", value: true }, { text: "Unverified", value: false }];

    // Methods
    const submitDisabled = (): boolean => {
        return false;
    }

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        usersApi
            .put(user.id, user)
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success("User has been successfully updated.");
                }
            })
    }

    const grantAdminAccess = (event: any): void => {
        event.preventDefault();

        let confimAdmin = window.confirm("Are you sure you want to grant administrative access to this user?")

        if (confimAdmin) {
            usersApi
                .put(`grant/${user.id}`, {})
                .then((response: FetchResponse) => {
                    if (response.success) {
                        setUser({ ...user, isAdmin: true });
                        notifier.success(`${user.firstName} ${user.lastName} has been granted admin access.`);
                    }
                })
        }
    }

    const revokeAdminAccess = (event: any): void => {
        event.preventDefault();
        usersApi
            .put(`revoke/${user.id}`, {})
            .then((response: FetchResponse) => {
                if (response.success) {
                    setUser({ ...user, isAdmin: false });
                    notifier.success(`Admin access has been revoked for ${ user.firstName } ${ user.lastName }.`);
                }
            })
    }

    const sendVerificationEmail = (event: any): void => {
        event.preventDefault();
        setEmailVerificationSent(true);
        usersApi
            .put(`verify/${user.id}`, {})
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success(`A verification email will be sent to ${user.emailAddress}.`);
                }
                else {
                    setEmailVerificationSent(false);
                }
            })
    }

    const sendChangePasswordEmail = (event: any): void => {
        event.preventDefault();
        setChangePasswordSent(true);
        usersApi
            .put(`password/${user.id}`, {})
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success(`A password change email will be sent to ${user.emailAddress}.`);
                }
                else {
                    setChangePasswordSent(false);
                }
            })
    }

    const updateGroups = (groups: Group[]): void => {
        const groupIds = groups.map(g => g.id);
        setUser({ ...user, groups: groups, groupIds: groupIds });
    }

    return (
        <section id="user-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Edit User</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/users") }}>Cancel</Button>
                        <Button disabled={submitDisabled()} primary={true}>Save</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>Contact</h2>
                        <div>
                            <label htmlFor="firstName">First Name</label>
                            <Input
                                id="firstName"
                                name="firstName"
                                onChange={(e) => setUser({ ...user, firstName: e.target.value?.toString() || "" })}
                                value={user.firstName} />
                        </div>
                        <div>
                            <label htmlFor="lastName">Last Name</label>
                            <Input
                                id="lastName"
                                name="lastName"
                                onChange={(e) => setUser({ ...user, lastName: e.target.value?.toString() || "" })}
                                value={user.lastName} />
                        </div>
                        <div>
                            <label htmlFor="emailAddress">Email</label>
                            <Input
                                id="emailAddress"
                                name="emailAddress"
                                value={user.emailAddress}
                                style={{ backgroundColor: "#eee" }}
                                readOnly />
                        </div>
                        <div>
                            <label htmlFor="organization">Organization</label>
                            <Input
                                id="organization"
                                name="organization"
                                onChange={(e) => setUser({ ...user, organization: e.target.value?.toString() || "" })}
                                value={user.organization} />
                        </div>

                        <h2>Settings</h2>
                        <div>
                            <label htmlFor="status">Status</label>
                            <DropDownList
                                data={["Active", "Inactive", "Rejected"]}
                                value={user.status === 0 ? "Inactive" : user.status}
                                onChange={(e) => { setUser({ ...user, status: e.target.value }) }}
                            />
                        </div>

                        <div>
                            <label htmlFor="emailVerification">Email Verified</label>
                            <DropDownList
                                data={emailVerifiedDropDownOptions}
                                value={emailVerifiedDropDownOptions.find(o => o.value === user.emailVerified)}
                                onChange={(e) => { setUser({ ...user, emailVerified: e.target.value.value }) }}
                                textField="text"
                                dataItemKey="value" />
                            {!user.emailVerified &&
                                <Button onClick={(e) => { sendVerificationEmail(e) }} style={{ marginLeft: "1rem" }} disabled={emailVerificationSent}>Resend Verification</Button>
                            }
                        </div>

                        <div>

                            <label htmlFor="groups">Group(s)</label>
                            <div>
                                <MultiSelectWithCreate
                                    name="groups"
                                    data={groups}
                                    value={user.groups}
                                    onChange={(e: any) => { updateGroups(e.target.value) }}
                                    onCreate={(updatedGroups: Group[]) => { updateGroups(updatedGroups) }}
                                />
                            </div>
                        </div>

                        <h2>Password Reset</h2>
                        <div>
                            <Button onClick={(e) => { sendChangePasswordEmail(e) }} style={{ marginLeft: "1rem" }} disabled={changePasswordSent}>Send Password Reset Email</Button>
                        </div>

                        <h2>Administrative Access</h2>
                        <div>
                            {user.isAdmin && <>
                                <div style={{ marginBottom: "1rem", textTransform: "uppercase", fontSize: ".75rem", fontWeight: "bold", color: "#f00" }}>This user has been granted administrative access</div>
                                <Button onClick={(e) => { revokeAdminAccess(e) }}>Revoke Administrative Access</Button>
                            </>}
                            {!user.isAdmin && <>
                                <div style={{ marginBottom: "1rem", textTransform: "uppercase", fontSize: ".75rem", fontWeight: "bold" }}>This user is not an admin</div>
                                <Button onClick={(e) => { grantAdminAccess(e) }}>Grant Administrative Access</Button>
                            </>}
                        </div>

                        <h2>Properties</h2>
                        <div className="properties">
                            <div>Created Date</div>
                            {user.createdDate.toLocaleDateString()}

                            {/*<div>Last Changed</div>
                            ToDo User Name (Date)*/}

                            <div>Login Count:</div>
                            {user.loginCount}

                            <div>Last Login:</div>
                            {user.lastLogin.toLocaleDateString()}
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}
import React, { useReducer, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import '@progress/kendo-theme-default/dist/all.css';

import { useAuth0 } from "@auth0/auth0-react";

import './custom.css'

import { SplashScreen } from "./splash-screen";
import { RoutedApp } from "./routed-app";
import { Notifications } from "./notifications";

import { DispatchContext, appReducer, initialAppState, TenantContext, AppStateContext, tenantReducer, initialTenantState, TenantActionType } from "./state";
import useFetch, { FetchResponse } from "./useFetch";

export const App = () => {
    // State
    const [appState, dispatch] = useReducer(appReducer, initialAppState);
    const [tenantState, tenantDispatch] = useReducer(tenantReducer, initialTenantState);

    const [loading, setLoading] = useState(true);

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const tenantsApi = useFetch("/api/tenants", false);

    useEffect(() => {
        if (!isAuthenticated) {
            setLoading(false);
            return;
        };
        console.log("Fetching tenants");
        tenantsApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    setLoading(false);
                    tenantDispatch({
                        type: TenantActionType.SetTenants,
                        tenants: response.result.tenants
                    });
                }
            });
    }, [isAuthenticated]);

    return (
        <SplashScreen>
            {loading && <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>}
            <TenantContext.Provider value={tenantState}>
                <AppStateContext.Provider value={appState}>
                    <DispatchContext.Provider value={{ dispatch: dispatch, tenantDispatch: tenantDispatch }}>
                        <Notifications />
                        <Router>
                            <RoutedApp />
                        </Router>
                    </DispatchContext.Provider>
                </AppStateContext.Provider>
            </TenantContext.Provider>
        </SplashScreen>
    );
}

import React, { useContext, useEffect } from "react";

import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

import { AppStateContext, DispatchContext, ActionType } from "./state";

export const Notifications = () => {
    const { successMessage, errorMessage } = useContext(AppStateContext);
    const { dispatch } = useContext(DispatchContext);

    useEffect(() => {
        if (successMessage) {
            setTimeout(clearSuccessNotification, 8000)
        }
        if (errorMessage) {
            setTimeout(clearErrorNotification, 8000)
        }
    }, [successMessage, errorMessage]);

    const clearErrorNotification = (): void => {
        dispatch({ type: ActionType.ClearErrorNotification });
    }

    const clearSuccessNotification = (): void => {
        dispatch({ type: ActionType.ClearSuccessNotification });
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", position: "absolute", top: "0", left: "0" }}>
            <NotificationGroup>
                <Fade enter={true} exit={true}>
                    {errorMessage && <Notification
                        type={{ style: 'error', icon: true }}
                        closable={true}
                        onClose={() => clearErrorNotification()}
                    >
                        <span>{errorMessage}</span>
                    </Notification>}
                </Fade>
                <Fade enter={true} exit={true}>
                    {successMessage && <Notification
                        type={{ style: 'success', icon: true }}
                        closable={true}
                        onClose={() => clearSuccessNotification()}
                    >
                        <span>{successMessage}</span>
                    </Notification>}
                </Fade>
            </NotificationGroup>
        </div>
    );
}
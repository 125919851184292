import React, { useEffect, useState, FormEvent } from "react";
import { useHistory } from "react-router";

import { useAuth0 } from "@auth0/auth0-react";

import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';

import { HtmlTextArea } from "./html-text-area";

import useFetch, { FetchResponse } from "./useFetch";
import useNotification from "./useNotification";

export const EditEmailPage = (props: any) => {
    // State
    const [templates, setTemplates] = useState<{ approvalSubject: string, approvalMessage: string}>({ approvalSubject: "", approvalMessage: ""});

    const history = useHistory();

    // Auth0
    const { isAuthenticated } = useAuth0();

    // API
    const emailTemplateApi = useFetch("/api/email/templates");

    //Notifications
    const notifier = useNotification();

    // Effects
    useEffect(() => {
        if (isAuthenticated) {
            emailTemplateApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        const templates = response.result;
                        setTemplates(
                            {
                                approvalSubject: templates.approval.subject,
                                approvalMessage: templates.approval.message
                            }
                        );
                    }
                });
        }
    }, [isAuthenticated])

    // Methods
    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();

        emailTemplateApi
            .put("email-templates", { approval: { subject: templates.approvalSubject, message: templates.approvalMessage } })
            .then((response: FetchResponse) => {
                if (response.success) {
                    notifier.success("Email tempaltes successfully updated");
                }
            });
    }

    return (
        <section id="email-page">
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>Edit Email Templates</h1>
                    <div className="controls">
                        <Button onClick={(e) => { e.preventDefault(); history.push("/") }}>Cancel</Button>
                        <Button primary={true}>Save</Button>
                    </div>
                </div>
                <div className="page">
                    <div className="content">
                        <h2>New Account Approval</h2>
                        <div>
                            <label htmlFor="approval-subject">Subject</label>
                            <Input
                                id="approval-subject"
                                name="approval-subject"
                                required
                                onChange={(e) => setTemplates({ ...templates, approvalSubject: e.target.value?.toString() || "" })}
                                value={templates.approvalSubject} />
                        </div>
                        <div>
                            <HtmlTextArea
                                label="Message (HTML)"
                                value={templates.approvalMessage}
                                onChange={(value: string) => setTemplates({ ...templates, approvalMessage: value })}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}